// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anna-palautetta-js": () => import("./../../../src/pages/anna-palautetta.js" /* webpackChunkName: "component---src-pages-anna-palautetta-js" */),
  "component---src-pages-en-avoid-slipping-js": () => import("./../../../src/pages/en/avoid-slipping.js" /* webpackChunkName: "component---src-pages-en-avoid-slipping-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-principles-js": () => import("./../../../src/pages/en/principles.js" /* webpackChunkName: "component---src-pages-en-principles-js" */),
  "component---src-pages-en-sign-up-helsinki-js": () => import("./../../../src/pages/en/sign-up/helsinki.js" /* webpackChunkName: "component---src-pages-en-sign-up-helsinki-js" */),
  "component---src-pages-ilmoittautuminen-helsinki-js": () => import("./../../../src/pages/ilmoittautuminen/helsinki.js" /* webpackChunkName: "component---src-pages-ilmoittautuminen-helsinki-js" */),
  "component---src-pages-ilmoittautuminen-kuopio-js": () => import("./../../../src/pages/ilmoittautuminen/kuopio.js" /* webpackChunkName: "component---src-pages-ilmoittautuminen-kuopio-js" */),
  "component---src-pages-ilmoittautuminen-lahti-js": () => import("./../../../src/pages/ilmoittautuminen/lahti.js" /* webpackChunkName: "component---src-pages-ilmoittautuminen-lahti-js" */),
  "component---src-pages-ilmoittautuminen-oulu-js": () => import("./../../../src/pages/ilmoittautuminen/oulu.js" /* webpackChunkName: "component---src-pages-ilmoittautuminen-oulu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kokemukset-js": () => import("./../../../src/pages/kokemukset.js" /* webpackChunkName: "component---src-pages-kokemukset-js" */),
  "component---src-pages-periaatteet-js": () => import("./../../../src/pages/periaatteet.js" /* webpackChunkName: "component---src-pages-periaatteet-js" */),
  "component---src-pages-sv-anmalning-helsingfors-js": () => import("./../../../src/pages/sv/anmalning/helsingfors.js" /* webpackChunkName: "component---src-pages-sv-anmalning-helsingfors-js" */),
  "component---src-pages-sv-ge-feedback-js": () => import("./../../../src/pages/sv/ge-feedback.js" /* webpackChunkName: "component---src-pages-sv-ge-feedback-js" */),
  "component---src-pages-sv-index-js": () => import("./../../../src/pages/sv/index.js" /* webpackChunkName: "component---src-pages-sv-index-js" */),
  "component---src-pages-sv-minska-halkrisken-js": () => import("./../../../src/pages/sv/minska-halkrisken.js" /* webpackChunkName: "component---src-pages-sv-minska-halkrisken-js" */),
  "component---src-pages-sv-principer-js": () => import("./../../../src/pages/sv/principer.js" /* webpackChunkName: "component---src-pages-sv-principer-js" */),
  "component---src-pages-talvihoitovinkit-js": () => import("./../../../src/pages/talvihoitovinkit.js" /* webpackChunkName: "component---src-pages-talvihoitovinkit-js" */),
  "component---src-pages-tavoitteet-js": () => import("./../../../src/pages/tavoitteet.js" /* webpackChunkName: "component---src-pages-tavoitteet-js" */),
  "component---src-pages-valta-liukastumiset-js": () => import("./../../../src/pages/valta-liukastumiset.js" /* webpackChunkName: "component---src-pages-valta-liukastumiset-js" */),
  "component---src-pages-varoitukset-js": () => import("./../../../src/pages/varoitukset.js" /* webpackChunkName: "component---src-pages-varoitukset-js" */)
}

